<template>
  <div id="mainDiv">
    <aboutPage
      v-show="aboutPageActive"
    />
    <notepad 
      style="margin-top: 2%;"
    />
    <calculatorPage
      v-show="calcPageActive"
    />
    <parser
      v-show="parserActive" 
    />
  </div>
</template>

<script>
import notepad from '../components/calculators/notepad.vue'; 
import calculatorPage from '../components/calculatorPage.vue'
import aboutPage from '../components/aboutPage.vue'
import parser from '../components/parser/parser.vue'

export default {
  name: 'CalculatorCorner',
  components: {
    calculatorPage,
    aboutPage,
    notepad,
    parser,
  },
  data() {
    return {
      bandState: true,
      calcPageActive: true,
      aboutPageActive: true,
      colorState: true,
      parserActive: true,
    }
  }
};
</script>

<style lang="scss" scoped>
#mainDiv {
  overflow-y: visible;
  z-index: 1000;
}
</style>