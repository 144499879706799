<template>
  <div style="display:inline-block; margin:2%;">
    <el-collapse>
      <h2>
        Color Calculator
      </h2>
      <el-select
        v-model="conversion"
        placeholder="Select a converter..."
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <p>Input</p>
      <div id="portForm">
        <el-input
          v-model="input1"
          type="form" 
        /><br>
        <el-input
          v-model="input2"
          type="form"
        /><br>
        <el-input
          v-model="input3"
          type="form"
        /><br>
      </div>
      <p>Solution</p>
      <el-input
        v-model="solution"
        style="font-size: 8pt;"
      /><br><br>
    </el-collapse>
  </div>
</template>

<script>
    export default {
        name: 'XyYCalc',
        data: function() {
            return {
                input1: 100,
                input2: 0.3127,
                input3: 0.329,
                value: '',
                conversion: 'LxyShow',
                options: [
                    {
                        value: 'LxyShow',
                        label: 'Lxy -> XYZ'
                    },
                    {
                        value: 'XYZShow',
                        label: 'XYZ -> Lxy'
                    }
                ]
            }
        },
        computed: {
            solution: {
                get() {
                  if (this.conversion == 'LxyShow') {
                    var actualXValue = (Number(this.input2)*Number(this.input1))/Number(this.input3);
                    var actualYValue = Number(this.input1);
                    var actualZValue = (((1-Number(this.input2)-Number(this.input3))*Number(this.input1))/Number(this.input3));
                    return actualXValue.toFixed(3) + ', ' + actualYValue.toFixed(3) + ', ' + actualZValue.toFixed(3);
                  } else if (this.conversion == 'XYZShow') {
                    var actualLValue = Number(this.input2);
                    var actualxValue = (Number(this.input1)/(Number(this.input1) + Number(this.input2) + Number(this.input3)));
                    var actualyValue = (Number(this.input2)/(Number(this.input1) + Number(this.input2) + Number(this.input3)));
                    return actualLValue.toFixed(4) + ', ' + actualxValue.toFixed(4) + ', ' + actualyValue.toFixed(4);
                  } else {
                    return '';
                  }
                },
                set(val) {
                  this.actualValue = val;
                }
            }
        }
      }              
</script>

<style>
</style>



