<template>
  <div style="margin-top: 100px;">
    <el-collapse>
      <h2>Welcome to the Learning Corner</h2>
      <p class="article">
        The Learning Corner is meant to be a place to help exchange information about the LED Video industry.<br><br>
        The information here will grow over time. Like everything else on this site, please be patient while I collect and compose the information for public consumption.
      </p> 
    </el-collapse>
  </div>
</template>
<script>
    export default {
        name: 'LearningAbout'
    };
</script>

<style>
</style>
