<template>
  <div>
    <WallSim />
  </div>
</template>

<script>
import WallSim from '@/components/custom/wallSim.vue'
export default {
  name: 'WallSimulator',
  components: {
    WallSim,
  },
}
</script>

<style>

</style>