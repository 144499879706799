import Vue from 'vue'
import VueRouter from 'vue-router'
import learningCorner from '../views/learningCorner.vue'
import wallSimulator from '../views/wallSimulator.vue'
import calculatorCorner from '../views/calculatorCorner.vue'
import aboutUs from '../views/aboutUs.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/calculatorCorner',
    name: 'calculatorCorner',
    component: calculatorCorner,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/wallSimulator',
    name: 'wallSimulator',
    component: wallSimulator,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/learningCorner',
    name: 'learningCorner',
    component: learningCorner,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/',
    name: 'aboutUs',
    component: aboutUs,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/ventana',
    name: 'ventana',
    component: () => import('../components/custom/ventana.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../components/login/Signup.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login/Login.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../components/login/ForgotPassword.vue'),
    meta: {
      noAuth: true
    }
  }
]

export const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});



export default router
