<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div 
    id="wallSimMainDiv"
  >
    <canvas id="mainCanvas" ref="canvas" />
    
    <el-card id="wallStatsCard">
      <div class="inline-section" style="width: 50%; display: inline-table;">
        <h2>Wall Statistics</h2>
        <el-select 
          v-model="selectedWall"
          placeholder="Select a wall"
        >
          <el-option
            v-for="rect in uniqueRects"
            :key="rect.wallName"
            :label="String('Name: ' + rect.wallName + ' (Tile W: ' + rect.width + ' H: ' + rect.height + ')')"
            :value="rect.wallName"
          />
        </el-select><br>
      </div>
      <div class="inline-section" style="width: 50%; display: inline-table;">
        <label class="article"> -- {{ selectedWall }} --</label><br>
        <label class="article"> Total power: {{ totalAmps }} amps</label><br>
        <label class="article"> Total weight: {{ totalWeight }} lbs</label><br><br>
      </div>
    </el-card>
    
    <!-- Wall Sim Inputs -->
    
    <el-card 
      id="wallInput"
    >
      <div class="inline-section">
        <h2>Panel Specs</h2>
        <p class="smallFont">
          Pixels Wide<br>
          <el-input
            v-model="pppWidth"
            type="form"
            value=""
          />
        </p>
        <p class="smallFont">
          Pixels Tall<br>
          <el-input
            v-model="pppHeight"
            type="form"
            value=""
          />
        </p>
        <p class="smallFont">
          Weight (pounds):<br>
          <el-input
            v-model="weight"
            type="form"
            value=""
          />
        </p>
        <p class="smallFont">
          Watts:<br>
          <el-input
            v-model="watts"
            type="form"
            value=""
          />
        </p>
      </div>
      <div class="inline-section">
        <h2>Wall Info</h2>
        <p class="smallFont">
          Name<br>
          <el-input
            v-model="wallName"
            type="form"
            value=""
          />
        </p>
        <p class="smallFont">
          Width (panels)<br>
          <el-input
            v-model="widthIn"
            type="form"
            value=""
          />
        </p>
        <p class="smallFont">
          Height (panels)<br>
          <el-input
            v-model="heightIn"
            type="form"
            value=""
          />
        </p>
        <el-select
          v-model="volts"
          value="208"
          name="Voltage"
          placeholder="Voltage"
        > 
          <el-option
            v-for="item in voltOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="inline-section">
        <el-button
          class="submitButton"
          type="success"
          style="color:#3BBA9C"
          @click="drawWall()"
        >
          <i class="el-icon-s-grid" />
          Create Wall
        </el-button><br>
      </div>
      <div class="inline-section">
        <el-button
          class="submitButton"
          @click="addText()"
        >
          <i class="el-icon-edit" />
          Add Textbox
        </el-button><br>
      </div>
      <div class="inline-section">
        <h3>Switch</h3>
        <el-input
          v-model="switchPorts"
          style="width: 40%;"
          type="form"
          value=""
        />
        <el-button
          class="submitButton"
          style="white-space: normal;"
          @click="drawSwitch()"
        >
          Add
        </el-button>
      </div><br>
      <h2>Coloring</h2>
      <div class="inline-section">
        <p
          class="smallFont"
          style="vertical-align: middle;"
        >
          <el-color-picker
            v-model="color"
            color-format="rgb"
            :predefine="predefineColors"
          />
        </p>
        <el-button
          class="submitButton"
          @click="changeColor()"
        >
          <i class="el-icon-brush" />
          Change Color
        </el-button><br>
      </div>
      <div class="inline-section">
        <h3>Tile Shading</h3>
        <el-button
          class="submitButton"
          icon="el-icon-bottom" 
          @click="changeFill('topToBottom')"
        />
        <el-button
          class="submitButton"
          icon="el-icon-top" 
          @click="changeFill('bottomToTop')"
        /><br>
        <el-button
          class="submitButton"
          icon="el-icon-back"
          @click="changeFill('rightToLeft')"
        />
        <el-button
          class="submitButton"
          icon="el-icon-right" 
          @click="changeFill('leftToRight')"
        /><br>
      </div>
      <p>Cable Paint Mode</p>
      <el-switch
        v-model="cableDraw"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
      <p>Power Paint Mode</p>
      <el-switch
        v-model="powerPuck"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
      <p>Data Paint Mode</p>
      <el-switch
        v-model="dataPuck"
        active-color="#13ce66"
        inactive-color="#ff4949"
      /><br><br>
      <h2>Canvas Management</h2>
      <div class="inline-section">
        <el-button @click="downloadCanvas()">
          <i class="el-icon-download" />
          Download Canvas
        </el-button>
        <el-upload
          action="#"
          :before-upload="uploadCanvas"
          :auto-upload="true"
        >
          <el-button>
            <template #trigger />
            <i class="el-icon-upload2" />
            Import Canvas
          </el-button>
        </el-upload>
      </div>
      <div class="inline-section">
        <el-button
          class="submitButton"
          style="color: rgb(255, 100, 100)"
          @click="clearSelected()"
        >
          <i class="el-icon-error" />
          Clear Selected
        </el-button><br>
        <el-button
          id="calcButton2"
          class="submitButton"
          style="color: rgb(255, 100, 100)"
          @click="clearAll()"
        >
          <i class="el-icon-delete" />
          Clear Canvas
        </el-button><br>
      </div>
    </el-card>
  </div>
</template>
    
    <script>
    import { fabric } from "fabric";
    import { mapState } from "vuex";
    
    export default {
      name: "WallSimulator",
      data() {
        return {
          activeNames: ["1"],
          cableDraw: false,
          canvas: null,
          canvasJSON: '',
          color: 'rgb(0, 126, 126)',
          colorInput: '',
          dataPuck: false,
          dataPucks: [],
          heightIn: 10,
          pppHeight: 216,
          pppWidth: 384,
          powerPuck: false,
          powerPucks: [],
          predefineColors: [
            'rgb(255, 0, 0)',
            'rgb(0, 0, 255)',
            'rgb(0, 255, 0)',
            'rgb(255, 255, 0)',
            'rgb(0, 255, 255)',
            'rgb(255, 0, 255)',
          ],
          selectedWall: '',
          switchPorts: 12,
          value: "",
          voltOptions: [
            {
              value: 110,
              label: "110v",
            },
            {
              value: 208,
              label: "208v",
            },
            {
              value: 240,
              label: "240v",
            },
          ],
          volts: 208,
          wallName: 'Wall 1',
          watts: 30,
          weight: 18,
          widthIn: 10,
        };
      },
      computed: {
        ...mapState([
          'panelConst',
          'wallConst'
        ]),
        allRects() {
          if (this.canvas) {
            const objects = this.canvas.getObjects();
            const rects = objects.filter(rect => rect.type === 'rect');
            return rects;
          } else {
            return [];
          }
        },
        uniqueRects() {
          const names = this.allRects.map(rect => rect.wallName);
          return this.allRects.filter((rect, index) => names.indexOf(rect.wallName) === index);
        },
        totalAmps() {
              const selectedRectsByWall = this.allRects.filter(rect => rect.wallName === this.selectedWall);
              const totalAmps = selectedRectsByWall.reduce((sum, rect) => sum + rect.amps, 0);
              return totalAmps.toFixed(3);
        },
        totalWeight() {
          const selectedRectsByWall = this.allRects.filter(rect => rect.wallName === this.selectedWall);
          const totalWeight = selectedRectsByWall.reduce((sum, rect) => {
            return sum + Number(rect.weight); // Ensure rect.weight is a number
          }, 0);          
          return Math.round(Number(totalWeight));
        },    
        pWidth: {
            get() {
                var actualValue = Number(this.pppWidth);
                return actualValue;
            },
            set(val) {
                this.actualValue = val;
            }
        },
        pHeight: {
            get() {
                var actualValue = Number(this.pppHeight);
                return actualValue;
            },
            set(val) {
                this.actualValue = val;
            }
        },
        heightOut: {
            get() {
                var actualValue = Number(this.heightIn);
                return actualValue;
            },
            set(val) {
                this.actualValue = val;
            }
        },
        widthOut: {
            get() {
                var actualValue = Number(this.widthIn);
                return actualValue;
            },
            set(val) {
                this.actualValue = val;
            }
        },
      },
      mounted: function () {
        this.initializeCanvas();
        
        // Cabling vars ----------------------------------------------------------------------------
    
        let line = null;
        let isDown = false;
        const snap = 120;
        let circles = [];
    
        // Cabling stuff ---------------------------------------------------------------------------

        this.canvas.on('mouse:down', (options) => {
          isDown = true;
          const pointer = this.canvas.getPointer(options.e);
          const points = [pointer.x, pointer.y, pointer.x, pointer.y];
    
          if (this.cableDraw) {
            // Create a new line object
            line = new fabric.Line(points, {
              strokeWidth: 3,
              fill: 'rgb(0, 50, 250)',
              stroke: 'rgb(0, 50, 250)',
              originX: 'center',
              originY: 'center',
              selectable: true
            });
            this.canvas.add(line);
          }
          if (this.cableDraw || this.powerPuck || this.dataPuck) {
            // Disable selection during mouse down
            this.canvas.selection = false;
    
            // Lock all the tiles on the canvas
            const objects = this.canvas.getObjects();
            for (let obj of objects) {
              obj.lockMovementX = true;
              obj.lockMovementY = true;
              obj.lockRotation = true;
              obj.lockScaling = true;
            }
          }
        });
    
        this.canvas.on('mouse:move', (options) => {
          if (!isDown) return; // Check if mouse is down and cable drawing is enabled
          const pointer = this.canvas.getPointer(options.e);
          const objects = this.canvas.getObjects('rect');
          let targetRect = null;
    
          // Check if pointer is close to any rectangle object
          for (let obj of objects) {
            if (obj) {
                const objCenter = obj.getCenterPoint();
                if (Math.abs(pointer.x - objCenter.x) < snap && Math.abs(pointer.y - objCenter.y) < snap) {
                  pointer.x = objCenter.x;
                  pointer.y = objCenter.y;
                  targetRect = obj;
                  break;
                }
            }
          }
          
          // Power Puck stuff ---------------------------------------------------------------------

          if (this.powerPuck) {
              if (targetRect) {
                  // Check if a powerPuck already exists for the target rectangle
                  const targetPowerPuck = this.powerPucks.find(powerPuck => {
                      return powerPuck.originalLeft === targetRect.left && powerPuck.originalTop === targetRect.top;
                  });

                  if (!targetPowerPuck) {
                      const group = new fabric.Group([], {
                          left: targetRect.left,
                          top: targetRect.top,
                          selectable: true
                      });

                      const smallRect = new fabric.Rect({
                          left: targetRect.left + targetRect.width * 0.15,
                          top: targetRect.top + 10,
                          width: targetRect.width * 0.65,
                          height: targetRect.height * 0.3,
                          fill: 'rgb(50, 150, 0)',
                          stroke: 'black',
                          strokeWidth: 6,
                          selectable: true
                      });

                      const icon = new fabric.Text('Power', {
                          left: smallRect.left + smallRect.width / 2,
                          top: smallRect.top + smallRect.height / 2,
                          fontSize: 80,
                          fontFamily: 'FontAwesome',
                          originX: 'center',
                          originY: 'center',
                          selectable: true
                      });
                      this.powerPucks.push({
                          originalLeft: targetRect.left,
                          originalTop: targetRect.top,
                          group: group
                      });
                      group.addWithUpdate(smallRect); // Add smallRect to the group
                      group.addWithUpdate(icon); // Add icon to the group
                      this.canvas.add(group); // Add group to the canvas
                      this.canvas.bringToFront(group); // Bring group to the front
                  }
              }
          }
        });

        // Data Puck stuff -------------------------------------------------------------------------

        this.canvas.on('mouse:move', (options) => {
          if (!isDown) return; // Check if mouse is down
          const pointer = this.canvas.getPointer(options.e);
          const objects = this.canvas.getObjects('rect');
          let targetRect = null;

          // Check if pointer is close to any rectangle object
          for (let obj of objects) {
            if (obj) {
              const objCenter = obj.getCenterPoint();
              if (Math.abs(pointer.x - objCenter.x) < snap && Math.abs(pointer.y - objCenter.y) < snap) {
                pointer.x = objCenter.x;
                pointer.y = objCenter.y;
                targetRect = obj;
                break;
              }
            }
          }

          if (this.dataPuck) {
              if (targetRect) {
                // Check if a dataPuck already exists for the target rectangle
                const targetdataPuck = this.dataPucks.find(dataPuck => {
                return dataPuck.originalLeft === targetRect.left && dataPuck.originalTop === targetRect.top;
            });

            if (!targetdataPuck) {
                const group = new fabric.Group([], {
                  left: targetRect.left,
                  top: targetRect.top,
                  selectable: true
                });

                const smallRect = new fabric.Rect({
                  left: targetRect.left + targetRect.width * 0.15,
                  top: targetRect.top + targetRect.height / 1.5,
                  width: targetRect.width * 0.6,
                  height: targetRect.height * 0.3,
                  fill: 'blue',
                  stroke: 'black',
                  strokeWidth: 3,
                  selectable: true
                });

                const icon = new fabric.Text('Data', {
                  left: smallRect.left + smallRect.width / 2,
                  top: smallRect.top + smallRect.height / 2,
                  fontSize: 90,
                  fill: 'white',
                  fontFamily: 'FontAwesome',
                  originX: 'center',
                  originY: 'center',
                  selectable: true
                });
                this.dataPucks.push({
                  originalLeft: targetRect.left,
                  originalTop: targetRect.top,
                  group: group
                });
                group.addWithUpdate(smallRect); // Add smallRect to the group
                group.addWithUpdate(icon); // Add icon to the group
                this.canvas.add(group); // Add group to the canvas
                this.canvas.bringToFront(group); // Bring group to the front
            }
              }
          }
        });

        this.canvas.on('mouse:move', (options) => {
          if (!isDown) return; // Check if mouse is down and cable drawing is enabled
          const pointer = this.canvas.getPointer(options.e);
          const objects = this.canvas.getObjects('rect');
          let targetRect = null;
    
          // Check if pointer is close to any rectangle object
          for (let obj of objects) {
            if (obj) {
                const objCenter = obj.getCenterPoint();
                if (Math.abs(pointer.x - objCenter.x) < snap && Math.abs(pointer.y - objCenter.y) < snap) {
                pointer.x = objCenter.x;
                pointer.y = objCenter.y;
                targetRect = obj;
                break;
                }
            }
          }
    
          // Update the line's end point and render the canvas
          if (line) { // Check if line is not null
            line.set({ x2: pointer.x, y2: pointer.y });
            this.canvas.renderAll();
          }

        // Circle stuff --------------------------------------------------------------------------
        if (this.cableDraw) {
          if (targetRect) {
            // Check if a circle already exists for the target rectangle
            const targetCircle = circles.find(circle => {
            const circleCenter = circle.getCenterPoint();
            const targetRectCenter = targetRect.getCenterPoint();
            return circleCenter.x === targetRectCenter.x && circleCenter.y === targetRectCenter.y;
          });
          // If a circle doesn't exist and there are no other circles within 100px, create one at the center of the target rectangle
          if (!targetCircle && !isCircleWithinRange(pointer.x, pointer.y)) {
            const circle = new fabric.Circle({
              left: targetRect.getCenterPoint().x - 20,
              top: targetRect.getCenterPoint().y - 20,
              radius: 20,
              fill: this.color,
              stroke: 'black',
              strokeWidth: 3,
              selectable: true
            });
            this.canvas.add(circle);
            this.canvas.bringToFront(circle);
            circles.push(circle);
          }
        
    
            // Start a new line from the updated end point
            const points = [pointer.x, pointer.y, pointer.x, pointer.y];
            line = new fabric.Line(points, {
              strokeWidth: 10,
              fill: 'rgb(0, 50, 250)',
              stroke: 'rgb(0, 50, 250)',
              originX: 'center',
              originY: 'center',
              selectable: true
            });
            this.canvas.add(line);
            this.canvas.sendBackwards(line, true); // Send the line backwards but keep it in front of the rectangles
          }
        }
      });
    
        this.canvas.on('mouse:up', () => {
          isDown = false;
          line = null;

          // Unlock all the tiles on the canvas and make them selectable
          const objects = this.canvas.getObjects();
          for (let obj of objects) {
            obj.lockMovementX = false;
            obj.lockMovementY = false;
            obj.lockRotation = false;
            obj.lockScaling = false;
            obj.selectable = true;
          }
          this.canvas.selection = true;
          this.canvas.renderAll();
        });    
    
        function isCircleWithinRange(x, y) {
          for (let circle of circles) {
            const circleCenter = circle.getCenterPoint();
            if (Math.abs(x - circleCenter.x) < snap && Math.abs(y - circleCenter.y) < snap) {
              return true;
            }
          }
          return false;
        }
    
      
        // Snapping --------------------------------------------------------------------------------
        
        this.canvas.on('object:moving', (e) => {
          this.snappingListener(e);
        });
      
        // Zoom ------------------------------------------------------------------------------------
    
        this.canvas.on("mouse:wheel", opt => {
          const delta = opt.e.deltaY;
          let zoom = this.canvas.getZoom();
          zoom = Math.max(0.01, Math.min(20, zoom * 0.999 ** delta));
          this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          opt.e.preventDefault();
          opt.e.stopPropagation();
        });

        // Copy and paste --------------------------------------------------------------------------
    
        document.addEventListener('keydown', (event) => {
          if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
            this.copy();
          }
        });
    
        // Paste function
    
        document.addEventListener('keydown', (event) => {
          if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
            this.paste();
          }
        });
      },
    
        
      // Removes all listeners and disposes of the canvas when the component is unmounted
    
      beforeUnMount: function () {
        window.removeEventListener('resize', this.updateCanvasSize);
        document.removeEventListener('keydown', this.copy);
        document.removeEventListener('keydown', this.paste);
        this.canvas.off('mouse:down');
        this.canvas.off('mouse:move');
        this.canvas.off('mouse:up');
        this.canvas.off('mouse:wheel');
        this.canvas.off('object:moving');
        this.canvas.dispose();
        console.log('Canvas disposed');
      },
      methods: {
    
        // Canvas initialization ------------------------------------------------------------------
    
        initializeCanvas() {
          var canvas = new fabric.Canvas(this.$refs.canvas, {
            hoverCursor: "pointer",
            selection: true,
            selectionBorderColor: "green",
            backgroundColor: null,
            interactive: true,
          });
          this.canvas = canvas;
          this.updateCanvasSize();
          window.addEventListener('resize', this.updateCanvasSize);
          canvas.setZoom(0.1);
          canvas.renderAll();
        },

        // Canvas Size Adjustment ------------------------------------------------------------------
    
        updateCanvasSize() {
          const width = window.innerWidth;
          const height = window.innerHeight;
          this.canvas.setWidth(width);
          this.canvas.setHeight(height);
          this.canvas.calcOffset();
          this.canvas.renderAll();
        },
        snappingListener: function(e) {
          const movingObject = e.target;
          const baseSnapThreshold = 15;
          const snapThreshold = baseSnapThreshold / this.canvas.getZoom(); // Scale the snap threshold based on the zoom level
          let closestHorizontalSnap = null;
          let closestVerticalSnap = null;
    
          this.canvas.getObjects().forEach((obj) => {
            if (obj === movingObject) return; // Skip the moving object itself
    
            // Edges of the stationary object where the moving object could snap
            const objEdges = {
              left: obj.left,
              right: obj.left + obj.width,
              top: obj.top,
              bottom: obj.top + obj.height
            };
    
            // Potential snapping points for aligning corners
            const movingObjectCorners = {
              left: movingObject.left,
              right: movingObject.left + movingObject.width,
              top: movingObject.top,
              bottom: movingObject.top + movingObject.height
            };
    
            // Check for horizontal corner alignment
            Object.keys(objEdges).forEach((edgeKey) => {
              if (['left', 'right'].includes(edgeKey)) {
                // Horizontal alignment
                if (Math.abs(objEdges[edgeKey] - movingObjectCorners.left) < snapThreshold) {
                  closestHorizontalSnap = objEdges[edgeKey];
                } else if (Math.abs(objEdges[edgeKey] - movingObjectCorners.right) < snapThreshold) {
                  closestHorizontalSnap = objEdges[edgeKey] - movingObject.width;
                }
              } else {
                // Vertical alignment
                if (Math.abs(objEdges[edgeKey] - movingObjectCorners.top) < snapThreshold) {
                  closestVerticalSnap = objEdges[edgeKey];
                } else if (Math.abs(objEdges[edgeKey] - movingObjectCorners.bottom) < snapThreshold) {
                  closestVerticalSnap = objEdges[edgeKey] - movingObject.height;
                }
              }
            });
          });
    
          // Apply the closest snap positions for corner alignment
          if (closestHorizontalSnap !== null) {
            movingObject.set('left', closestHorizontalSnap);
          }
          if (closestVerticalSnap !== null) {
            movingObject.set('top', closestVerticalSnap);
          }
    
          movingObject.setCoords(); // Update the moving object's coordinates for accurate interaction
          this.canvas.renderAll(); // Re-render the canvas to apply changes
        },
    
        // Draw Switch ---------------------------------------------------------------------------
    
        drawSwitch: function () {
          const switchPorts = this.switchPorts; // User input for the number of switch ports
          const switchWidth = 1200; // Width of the large rectangle
          const switchHeight = 300; // Height of the large rectangle
          const switchRect = new fabric.Rect({
            fill: 'rgb(100, 100, 100)',
            opacity: 0.6,
            stroke: "white",
            strokeWidth: 1,
            width: switchWidth,
            height: switchHeight,
            left: (this.canvas.getWidth() - switchWidth) / 2, // Center the large rectangle horizontally
            top: (this.canvas.getHeight() - switchHeight) / 2, // Center the large rectangle vertically
            hasControls: false,
            renderOnAddRemove: false,
            wallName: 'Switch',
          });
    
          const rectWidth = switchWidth / switchPorts; // Width of each smaller rectangle
          const rectHeight = switchHeight; // Height of each smaller rectangle
    
          for (let i = 0; i < switchPorts; i++) {
            const rect = new fabric.Rect({
              fill: 'rgb(0, 150, 0)',
              opacity: 0.6,
              stroke: "white",
              strokeWidth: 1,
              width: rectWidth,
              height: rectHeight / 2,
              left: switchRect.left + (i * rectWidth), // Position each smaller rectangle horizontally
              top: switchRect.top + (rectHeight), // Align each smaller rectangle with the top edge of the large rectangle
              hasControls: false,
              renderOnAddRemove: false,
              wallName: 'Network Port'
            });
            this.canvas.add(rect);
            this.canvas.bringToFront(rect); 
          }
    
          const text = new fabric.Textbox('Switch', {
            left: switchRect.left,
            top: switchRect.top + switchHeight / 2,
            width: switchWidth,
            fontSize: 60, // Increase the font size for larger text
            textAlign: 'center',
            fill: 'white',
            hasControls: true,
            renderOnAddRemove: true,
          });
          this.canvas.add(text);
          this.canvas.bringToFront(text);
    
          this.canvas.add(switchRect);
          this.canvas.renderAll();
          this.canvas.sendToBack(switchRect);
        },
        
        // Wall Drawings method --------------------------------------------------------------------
    
        drawWall: function () {
          let rects = [];
          const canvasWidth = this.canvas.getWidth();
          const canvasHeight = this.canvas.getHeight();
          const wallWidth = this.widthOut * this.pppWidth;
          const wallHeight = this.heightOut * this.pppHeight;
          const left = (canvasWidth - wallWidth) / 2;
          const top = (canvasHeight - wallHeight) / 2;
    
          for (var j = 0; j < this.heightOut; j++) {
            for (var i = 0; i < this.widthOut; i++) {
              const rect = new fabric.Rect({
                fill: this.color,
                width: this.pWidth,
                height: this.pHeight,
                opacity: 0.6,
                stroke: "white",
                strokeWidth: 3,
                left: left + this.pppWidth * i,
                top: top + this.pppHeight * j,
                hasControls: false,
                renderOnAddRemove: false,
                amps: this.watts / this.volts,
                wallName: this.wallName,
                weight: this.weight,
              });
              rects.push(rect);
            }
          }
          this.canvas.add(...rects);
    
          // Calculate zoom level to show the whole wall
          const zoomX = canvasWidth / wallWidth;
          const zoomY = canvasHeight / wallHeight;
          const zoom = Math.min(zoomX, zoomY) * 0.5; // Adjust zoom level for better visibility
    
          this.canvas.setViewportTransform([zoom, 0, 0, zoom, canvasWidth / 2.4, canvasHeight / 2.4]); // Apply zoom level and center the viewport
          this.canvas.renderAll();
          rects = [];
        },
    
        // Tile shading stuff ----------------------------------------------------------------------
    
        changeFill: function(direction) {
          const selectedRects = this.canvas.getActiveObjects();
          const maxHeight = this.canvas.getHeight();
          const colorIn = this.color.replace("rgb", "").replace(")", "").replace("(", "")
          const colorValues = colorIn ? colorIn.split(',').map(value => (value.trim())) : null;
          const maxWidth = this.canvas.getWidth();
    
          if (colorValues.length !== 3 || colorValues.some(value => isNaN(value) || value < 0 || value > 255)) {
            alert('Invalid RGB format. Please enter valid RGB values separated by commas (e.g., 255, 0, 0)');
            return;
          }
          const startColor = colorValues;
          const endColor = [10, 10, 10];
    
          let minTop = maxHeight;
          let maxBottom = 0;
          let minLeft = maxWidth;
          let maxRight = 0;
    
          selectedRects.forEach((rect) => {
            if (rect.top < minTop) {
              minTop = rect.top;
            }
            if (rect.top + rect.height > maxBottom) {
              maxBottom = rect.top + rect.height;
            }
            if (rect.left < minLeft) {
              minLeft = rect.left;
            }
            if (rect.left + rect.width > maxRight) {
              maxRight = rect.left + rect.width;
            }
          });
    
          selectedRects.forEach((rect) => {
            let shadingDirection;
            if (direction === 'bottomToTop') {
              shadingDirection = 1 - (rect.top + rect.height - minTop) / (maxBottom - minTop);
            } else if (direction === 'rightToLeft') {
              shadingDirection = 1 - (rect.left - minLeft) / (maxRight - minLeft);
            } else if (direction === 'leftToRight') {
              shadingDirection = (rect.left + rect.width - minLeft) / (maxRight - minLeft);
            } else { // Default to top to bottom
              shadingDirection = (rect.top - minTop) / (maxBottom - minTop);
            }
    
            const interpolatedColor = startColor.map((startValue, i) => {
              const endValue = endColor[i];
              let value = startValue * (1 - shadingDirection) + endValue * shadingDirection;
              value = Math.round(value); // Round the interpolated value
              return value;
            });
            const fill = `rgba(${interpolatedColor[0]}, ${interpolatedColor[1]}, ${interpolatedColor[2]}, 1)`;
            rect.set('fill', fill);
          });
          this.canvas.requestRenderAll();
        },    
    
        // Copy and paste --------------------------------------------------------------------------
    
        copy: function () {
          this.clipboard = [];
          this.canvas.getActiveObjects().forEach((obj) => {
            const cloned = obj.toObject(['wallName', 'amps', 'weight']); // Include custom properties
            this.clipboard.push(cloned);
          });
        },
        paste: function () {
          this.canvas.discardActiveObject();
          const newObjects = [];
          fabric.util.enlivenObjects(this.clipboard, (objects) => {
            objects.forEach((obj) => {
              // Set custom properties
              obj.set({
                wallName: obj.wallName,
                amps: obj.amps,
                weight: obj.weight,
              });
              this.canvas.add(obj);
              newObjects.push(obj);
            });
            const group = new fabric.ActiveSelection(newObjects, {
              canvas: this.canvas,
            });
            this.canvas.setActiveObject(group);
            this.canvas.requestRenderAll();
          });
        },    

        // Change color ----------------------------------------------------------------------------
    
        changeColor: function () {
          this.canvas.getActiveObjects().forEach((obj) => {
            obj.set('fill', this.color)
          });
          this.canvas.renderAll();
        },
    
        // Textbox creation ------------------------------------------------------------------------
    
        addText: function () {
          var textbox = new fabric.Textbox("Enter Text", {
            fill: "rgb(150,150,150)",
            width: 200,
            height: 200,
            scaleX: 5,
            scaleY: 5,
          });
          this.canvas.add(textbox);
          this.canvas.renderAll();
        },
    
        // Download Canvas as JSON -----------------------------------------------------------------
    
        downloadCanvas: function () {
          const json = JSON.stringify(this.canvas.toJSON());
          const blob = new Blob([json], {type: 'application/json'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'canvas.json';
          link.click();
        },
    
        // Load Canvas -----------------------------------------------------------------------------
        
        uploadCanvas(file) {
          if (file.type !== 'application/json') {
            this.$message.error('File is not a JSON file');
            return false;
          }
    
          const reader = new FileReader();
          reader.onload = (e) => {
            const json = JSON.parse(e.target.result);
            this.canvas.loadFromJSON(json, this.canvas.renderAll.bind(this.canvas));
          };
          reader.readAsText(file);
          return false; // prevent the file from being uploaded to the server
        },
        
        // Clearing objects ------------------------------------------------------------------------
    
        clearSelected: function () {
          this.canvas.getActiveObjects().forEach((obj) => {
            this.canvas.remove(obj);
          });
          this.canvas.discardActiveObject().renderAll();
        },
        clearAll: function () {
          this.$confirm(
            "Are you sure you want to delete everything?",
            "Clear Canvas",
            {
              distinguishCancelAndClose: true,
              confirmButtonText: "Clear Canvas",
              cancelButtonText: "Cancel",
            }
          )
            .then(() => {
              this.$message({
                type: "info",
                message: "Canvas Cleared!",
              });
              this.canvas.clear();
              this.powerPucks = [];
              this.dataPucks = [];
            })
            .catch((action) => {
              this.$message({
                type: "info",
                message:
                  action === "cancel" ? "Your work is safe..." : "Staying on track",
              });
            });
        }
      }
    }
          
    </script>
    
    <style lang="scss" scoped>
    
    #mainCanvas {
      overflow: hidden;
    }
    #wallStatsCard {
      position: fixed;
      margin: 0;
      background-color: #212121;
      width: 40%;  /* Adjusted width */
      left: 50%;
      bottom: 1%;
      transform: translateX(-50%);
      border-style: outset;
      border-radius: 1em;
      border-width: 2px;
      border-color: rgb(55, 55, 55);
      box-shadow: 0px 0px 20px #909090;
      overflow: auto;
    }
    #wallInput {
      position: fixed;
      background-color: #212121;
      right: 1%;
      top: 10%;
      height: 80%; /* Adjusted height */
      max-width: 150px;
      border-style: outset;
      border-width: 2px;
      border-radius: 1em;
      border-color: rgb(55, 55, 55);
      box-shadow: 0px 0px 20px #909090;
      z-index: 1000;
      overflow: auto;
    }
    
    @media (max-width: 800px) {
      h2 {
        font-size: 10pt;
      }
      #mainCanvas {
        overflow: hidden;
        margin: auto;
        width: 100vw;  /* Adjusted width */
        height: 100vh; /* Adjusted height */
      }
      .canvas-container {
        top: 0; /* Adjusted top */
        overflow: hidden;
        margin: auto;
        width: 100vw;  /* Adjusted width */
        height: 100vh; /* Adjusted height */
      }
      .inline-section {
        display: inline-table;
        width: 50%;
      }
      .upper-canvas, .lower-canvas {
        overflow: hidden;
        margin: auto;
        width: 100vw;  /* Adjusted width */
        height: 100vh; /* Adjusted height */
      }
      #wallInput {
        position: absolute;
        left: 0;
        top: 80%;
        background-color: rgb(10,10,10);
        width: 375px;  /* Adjusted width */
        height: 15%;
        max-width: max-content;
      }
      #wallStatsCard {
        position: absolute;
        width: max-content;
        top: 66%;
        height: 12%;
      }
    }
    @media (min-width: 801px) {
      #mainCanvas {
        width: 4000px;
        height: 3000px;
      }
    }
    </style>