import Vue from 'vue'
import App from './App.vue'
import {router} from './router/index'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import './styles/style_variables.scss'
import store from './store/store'
//import firebase from 'firebase/app'

Vue.use(ElementUI, { locale });


Vue.config.productionTip = false

/*
const firebaseConfig = {
  apiKey: "AIzaSyCPss1iaGd-TdaiS8kTWGvTM6BBKXHqscM",
  authDomain: "led-corner-2ee48.firebaseapp.com",
  projectId: "led-corner-2ee48",
  storageBucket: "led-corner-2ee48.appspot.com",
  messagingSenderId: "881532964332",
  appId: "1:881532964332:web:8f46379ff5c6b29c6e44c9",
  measurementId: "G-P0T3P5DHLM"
}

firebase.initializeApp(firebaseConfig);
*/
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
