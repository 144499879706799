<template>
  <div>
    <el-collapse>
      <div>
        <!-- Begin LED Basics -->
        <h2>
          LED Basics
        </h2>
        <p class="article">
          In case you haven't figured it out yet, this whole website is based around LED Video Panels and their usage in the Audio Visual industry. 
          These LED panels assemble into an array and generally use cables to connect power and data across the array.  
          Once they are all connected, the user will send it a video signal from the LED Processor (sometimes called Sending Card or Sender).
          These arrays can be used in a large variety of applications from Live Events, Productions, Virtual Studios, Lighting Elements, and many more.
          This flexibility makes LED Panels a really important part of the AV Industry. 
          <br><br>In the following sections, we will attempt to teach you a variety of aspects of being in the LED Video world.
        </p>
      </div><br>
      <!-- Diode -->
      <el-collapse-item
        title="Simple LED"
        name="1"
      >
        <div class="pArticle">
          <h2>
            Basic LED Explained
          </h2><br>
          <div>
            <p class="article">
              LED stands for Light Emitting Diode. 
              A Diode is a simple electrical component where electricity flows in one direction (from anode to cathode) and goes through a electrical process that ends with photons (light) being emitted from the component.  
              <br><br>More specifically, it is a semiconductor that consists of an Anode (positive terminal) that is connected to a p-type layer and a Cathode (negative terminal) which is connected to the n-type layer.
              The layers are made from the same base semi-conductor material but are separately doped with different elements to create the p-type and n-type layers.  
            </p>
          </div><br><br>
          <img
            class="articleImag"
            src="../../assets/diode.png"
          ><br><br>
          <div
            style="margin: auto; width: 60%; color: #717171;"
          >
            <p
              class="article"
            >
              The image above shows a simple diode. 
            </p>
          </div>
          <div>
            <p class="article">
              <br><br>As the current flows across the semiconductor, the electrons from the n-layer fill the electron holes in the p-layer which in turn releases photons (light).
              In order to create different colors of the light, different elements are used.  
              This proved tricky for a long time since the Blue LED wasn't created until the 1970s. 
              Mixing Red, Green, and Blue at a variety of levels allows flexibility to create many colors. 
              Finally, the amount of voltage applied and the number of refreshes will dictate how bright the LED is.<br>
            </p>
          </div><br>
          <div>
            <p class="article">
              The typical LEDs used in the AV world are called Surface Mounted Diode (or SMD for short).  
              Each of these SMD LEDs has metal contacts that connect the SMD to the circuit board.  
              A typical has 4 or 6 of these metal contacts but can certainly range depending on the LED type and application. 
              The picture below gives you an example of a basic (soooo basic) SMD LED layout.<br><br>
            </p>
          </div><br>
          <img
            class="articleImag"
            src="../../assets/LED.png"
          ><br><br>            <br>
        </div><br>
      </el-collapse-item>
      <!-- LED Panel -->
      <el-collapse-item
        title="LED Panel"
        name="3"
      >
        <div class="pArticle">
          <h2>LED Panel</h2>
          <div>
            <div>
              <p class="article">
                Now that you understand a little (an extraordinarily little) about what an LED is, it's time to break down the components that make an LED panel. 
                There are a handful of basic components that all LED panels will have. 
                The main ones are the LED Modules, Receiver Card, HUB/Spine Board, Power Supply/Backpack, and Frame. 
                Each of those together will give you a basic LED panel and depending on other specifications, there will be various other features.
              </p>
            </div><br>
            <el-collapse style="margin-left: 0;">
              <!-- LED Module -->
              <el-collapse-item
                title="LED Module"
                name="1s"
              >
                <h2>LED Module</h2>   
                <div>
                  <p class="article">
                    The key components of an LED Display Module (or LDM for short) are the LED circuit board, driver chips, and LEDs. 
                    Panels have a variety of numbers of LED "Modules" that make up a single LED "Panel".  
                    The Driver chips (which are the brains of the LED Module) divide up the LED Module into smaller sections of LEDs. 
                    This is why you will often see lines of pixels missing a single color or even bright lines while the video is black.
                    In most quality LED Modules, there will be permanent memory built in that will house Serial Numbers and Calibration Data if applicable.
                  </p>
                </div><br>
                <img
                  class="articleImag"
                  src="../../assets/LED_module.png"
                ><br><br>
                <div>                        
                  <p class="article">
                    LED Modules come in all shapes and sizes. These different shapes and sizes serve many different purposes which often differentiate between specifications like indoor versus outdoor, viewing angle or viewing distance.<br><br>
                    Outdoor modules generally have a protective coating applied to all electronics that makes the modules weather resistent. Indoor modules on the other hand, will have exposed electronics that could get damaged in the elements. 
                    Often, indoor modules will have a smaller pixel pitch with the purpose of having a closer viewing distance while outdoor modules will more often be a lot wider of a pitch.<br><br>
                    All this to say, there are lots of considerations to take in when deciding on which LED module fits your application best.
                  </p>
                </div>
              </el-collapse-item>
              <!-- Receiver Card -->
              <el-collapse-item
                title="Receiver Card"
                name="2s"
              >
                <h2>Receiver Card</h2>
                <img
                  class="articleImag"
                  src="../../assets/receiver_card.png"
                ><br><br>
                <div>
                  <p class="article">
                    The Receiving Card in a panel is the "brain" of the panel.  
                    This is where most of the logic is carried out and is the part that communicates with the Processor. 
                    This part is usually the same for each manufacturer across all of their different panels. Receiver cards will only work with the manufacturer's processing system.
                  </p>
                </div>
              </el-collapse-item>
              <!-- HUB Board -->
              <el-collapse-item
                title="HUB Board"
                name="3s"
              >
                <h2>HUB Board</h2>   
                <img
                  style="width:40%; height:40%;"
                  src="../../assets/HUB_board.png"
                ><br><br>
                <div>
                  <p class="article">
                    A HUB Board is the board that connects the LED Modules to the Receiver Card.  This board is generally different for each LED Panel series. It will mount directly onto the frame of the LED Panel.  The HUB Board will house the Panel's serial number along with (usually) a certain amount of temporary memory.
                  </p>
                </div>
              </el-collapse-item>
              <!-- PSU/Backpack -->
              <el-collapse-item
                title="Power Supply/Backpack"
                name="4s"
              >
                <h2>Power Supply/Backpack</h2>
                <img
                  class="articleImag"
                  src="../../assets/backpack.png"
                ><br><br>
                <div>
                  <p class="article">
                    Each panel generally has a removable housing on the back that contains the Power Supply, connectors, and any internal cabling that might exist.<br><br>
                    Generally speaking, panels use ethernet ports for data and something similar to PowerCon True1 for the power connections. There is usually an indicator/button on the backpack that will be used to communicate state of the panel (generally yellow and red for errors).  Generally there is a label the communicates the panel information including useful things like per panel power consumption.
                  </p>
                </div>
              </el-collapse-item>
              <!-- Frame/Hardware -->
              <el-collapse-item
                title="Frame/Hardware"
                name="5s"
              >
                <h2>Frame/Hardware</h2>  
                <img
                  class="articleImag"
                  src="../../assets/hardware.png"
                ><br><br>
                <div>
                  <p class="article">
                    The LED Panel Frame/Hardware works to keep the LED Modules connected to the rest of the required electronics. The Hardware is the part of the panel that connects it to other panels or hanging/stacking equipment.
                    <br><br>There are a couple key pieces of hardware that are required for any system to be safely hung.  Starting from the top, there is the Hanger (also known as Header) which connects the LED wall to the rigging points.  Every panel type that is hangable will have its own specific type of Header.  
                    <br><br>To safely connect panels together, there are either Connection Plates that are separate pieces of hardware or the panel will have integrated latches (top to bottom and left to right) that a user will have to manually push together.  The Connection Plates (or blocks) generally come in several different flavors.  This includes 2-way Side or Bottom Plates (so they don’t stick out past the edge of the wall) or 4-way style that connect on the corners of 4 different panels. 
                    <br><br>For ground supported walls, you would replace the Hanger with a Base (sometimes called Footer).  For taller, ground supported walls (refer to the panel’s spec sheet for your application), there are various Stacking systems that brace the back of the wall (imagine scaffolding).  This provides rigidity to the wall as it gets taller and taller while providing good points for counterbalancing.  
                    <br>
                  </p>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div><br>
      </el-collapse-item>
      <!-- Basic System -->
      <el-collapse-item
        title="Basic System"
        name="4"
      >
        <div class="pArticle">
          <h2>
            Basic System
          </h2><br>
          <img
            class="articleImag"
            src="../../assets/wall.png"
          ><br>            
          <div>
            <p class="article">
              Let's take a look at how a basic system goes together.
            </p>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
    export default {
        name: 'BeginnerPage',
        data: function() {
            return {
                beginnerPageActive: true
            }
        }
    };
</script>

<style>
</style>
