/* eslint-disable vue/no-deprecated-v-bind-sync */
<template>
  <div
    id="app"
  >
    <navbar />
    <div>
      <router-view />
    </div><br><br>
  </div>
</template>
<script>
import navbar from './components/navbar.vue'

export default {
  name: 'App',
  components: {
    navbar
  },
};
</script>

<style>
</style>