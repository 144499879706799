<template>
  <div style="margin-top: 100px;">
    <el-collapse>
      <h2>
        The Calculators
      </h2>
      <p class="article">
        {{ title }} is for brainstorming purposes only. 
        I am doing everything in my power to make sure the information on this website is accurate.
        All results seen here are estimations and it is required that a qualified tech verify the results by hand before using. Be responsible not lazy.
        That's my way of saying "Things will likely be broken. Proceed at your own risk!"  <br><br>
      </p>
    </el-collapse>
  </div>
</template>
<script>

import { mapState } from 'vuex'

    export default {
        name: 'AboutPage',
        data() {
            return {
                aboutPageActive: true
            }
        },
        computed: {
            ...mapState([
                'title'
            ])
        }
    };
</script>
<style>
</style>
